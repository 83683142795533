@blackgrey: #1b1b1b; @darkgrey: #959595; @lightgrey: #d2d2d2; @yellow-green: 
#fffb31; @darkgrey-footer: #484748;


/*@imagePath: ~"http://www.redesign.doemges.ag/templates/doemges-ag/images";*/
@fontPath: "fonts";
@imagePath: "images";

@standard-font-family: 'Fira Sans';

.regular-font {
	font-family: @standard-font-family;
	font-weight: 400;
}

.semi-bold-font {
	font-family: @standard-font-family;
	font-weight: 600;
}

.bold-font {
	font-family: @standard-font-family;
	font-weight: 700;
}

.light-font {
	font-family: @standard-font-family;
	font-weight: 300;
}


.font-size(@sizeValue) {
  @remValue: @sizeValue;
  @pxValue: (@sizeValue * 16);
  
  font-size: ~"@{remValue}rem";
}

.line-height(@sizeValue) {
  @remValue: @sizeValue;
  @pxValue: (@sizeValue * 16);
  line-height: ~"@{pxValue}px"; 
  line-height: ~"@{remValue}rem";
}


html {
	-webkit-font-smoothing: antialiased;
}

html, body {
	height: 100%;
	padding: 0;
	.standart-font;
	padding: 0;
	margin: 0;
}

body {
	 
}

h1, h2, h3, h4 {
  font-weight: bold;
  color: @blackgrey;
}

h1, h2 {
	.font-size(1.81);
	.line-height(1.81);
	font-weight: normal;
}

h1,h3 {
	
}
	
h1 {
	margin: 0;
	margin-bottom: 10px;
}

h2 {
	
	margin: 0;
	margin-bottom: 20px;
}

h3 {
	margin-bottom: 16px;
}

h3, h4 {
	.font-size(1.44);
}

p {
 margin: 0;
 margin-bottom: 20px;
 padding: 0;
}

img {
 display: block;
 border: 0;
}

img.auto-size {    
  height: auto;    
  max-width: 100%;    
}

ul {
	list-style-type:none;
	padding: 0;
	margin: 0;
}

li {
	.font-size(1);
	.line-height(1);
}

.clear {
	clear: both;	
}

.standart-font {
	.regular-font;
	.font-size(0.938);
	color: @blackgrey;
	.line-height(1.1875); 
}

a:link, a:active, a:visited, a:hover {
	.standart-font;
	color: @darkgrey;
	text-decoration: none;
}

a:hover {

}

/*-------------------------------LAYOUT-------------------------------------*/

#container {
	width: 100%;
	height: 100%;
	vertical-align: top;
	text-align: center; /* IE zentrieren Hack */
	border-top: 9px @yellow-green solid;
	margin: 0 auto;	
}

#header, #main, #footer {
	display: block;
	width: 100%;
}

#headercontent, #maincontent,
#footercontent, #submenuecontent {
	max-width: 940px;
	margin: auto;
}

#header, #headercontent {
 position: relative; 
 text-align: left;
 z-index: 9000;
}

#headercontent {
	height: 97px;
	padding-top: 27px;
}

#main {
	min-height: 100%;
	text-align: left;	
	.clear;
	margin: 0;
	/*margin-top: 14px;*/
	padding: 0;
	border: 0;	
}


#footer {
	display: block;
	min-height: 230px;
	background-color: @darkgrey-footer;
	.bold-font;
	.font-size(0.93);
	line-height: (1.25);	
	padding-top: 25px;	
}

#logo {
 display: block;
 float: left; 
   
 a:link, a:active, a:visited, a:hover {
 	display: block;
 	width: 194px;
 	height: 62px;
 	background: url('@{imagePath}/bha_logo.png') no-repeat 0; 	
 }
 
}

body.overlay-on { 
	overflow: hidden;
		
	#logo {
		a:link, a:active, a:visited, a:hover {
			background: url('@{imagePath}/bha_logo_nav_on.png') no-repeat 0; 	
		}
	}	
}

#menue-icon {
	display: none;
}

.row {
	clear: both;
}

.col-left {
	float: left;
	width: 38.54%;
}

.col-right {
	margin-left: 41.15%;
}

#system-message {

	.font-size(1);
	padding: 25px 0;
	
	a.close {
		display: none;
	}
	
	h4 {
		.font-size(1);
		margin-right: 5px;
		margin-top: 0;
		margin-bottom: 10px;
		float: left;
	}
}



/*-------------------------------NAV-------------------------------------*/

#sidenav, #mainnav, #subnav {
	display: block;
	/*width: 17.7%;*/	
	
	li {
		
		text-align: left;	
		
		a:link, a:active, a:visited, a:hover {
			display: block;		
			color:  @darkgrey;
			padding: 0;
			margin: 0;
			margin-right: 2.6%;
			margin-bottom: 4px;		
		}	
		&.active a, a:hover {
			color: @blackgrey;
		}
	}
}

#header #mainnav {
	width: 21px;
	height: 21px;
	background: url('@{imagePath}/mainnav_off.png') no-repeat 0;
	float: right;
	cursor: pointer;	
}

body.overlay-on #header #mainnav { 
	position: fixed;
	background: url('@{imagePath}/mainnav_on.png') no-repeat 0;	
}

#submenue #subnav {
	width: 100%;
	margin-top: 67px;
	margin-bottom: 30px;
	clear: both;

	li {
		float: left;
		margin-right: 3.125%;	
		
		a:link, a:active, a:visited, a:hover {
			display: block;
			.font-size(1);	
			.regular-font;	
			color:  @darkgrey;
			margin: 0;
							
		}
		
		&.current {
			a:link, a:active, a:visited, a:hover {
			display: block;	
			.bold-font;	
			color:  @blackgrey;
		 }			
		}
	}	
}

#footer #mainnav {
		
		li {
			width: 15%;
			margin-right: 2%;
			float: left;
		
			a:link, a:active, a:visited, a:hover {
					width: 100%;
					.bold-font;
					color: @yellow-green;
			}	
		
			&.parent {
				height: 230px;
				margin-right: 2.5%;							
								
				.nav-child li {
					width: 100%;
					margin: 0;
					float: none;
					
					a:link, a:active, a:visited {
						color: @darkgrey;
					}
										
					a:hover {
						color: @lightgrey;
					}
				}
				.nav-child li.current {			
					a:link, a:active, a:visited, a:hover {
							color: @lightgrey;
					}				
				}				
			}
		}
		
		li.nomargin {
			margin-right: 0;
		}
}

#footernav {
	width: 15%;
	float: left;
	
	li {
		text-align: left;
		float: none;
		
		a:link, a:active, a:visited, a:hover {
			width: 100%;
			.bold-font;			
		}	
		
		a:hover {
			color: @lightgrey;
		}
		
		&.active {
			a:link, a:active, a:visited, a:hover {
					color: @lightgrey;
			}	
		}
		
	}
	
}

.nav-overlay {
		background: rgba(0, 0, 0, 0) linear-gradient(to bottom, rgba(146, 146, 146, 0.75) 0%, rgba(146, 146, 146, 0.85) 100%) repeat scroll 0 0;
    color: rgb(0, 20, 122);
    display: none;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;   
    width: 100%;
    z-index: 8800;         
    
    .pos-center {
    	height: 100vh; 
    	margin-top: 124px;  		     
	    
			#mainnav-overlay {	
				position: relative;
				width: 100%;
				height: auto;
				margin: auto;
				float: none;
				text-align: center;						
							
				li {
					a:link, a:active, a:visited, a:hover {
						.light-font;
						.font-size(2.5);
						.line-height(3);
						color: white;
				  }	  
				  &.active a, a:hover {
						color: @yellow-green;
					}
				}
			}		
		}
}

body.responsiveView {

	.nav-overlay {
	
	
		
	#mainnav-overlay {
	
						
			li.parent {
				a:link, a:active, a:visited, a:hover {
					.font-size(2.5);
					.line-height(3);
				}
				
				li {
					a:link, a:active, a:visited, a:hover {
						.font-size(1.5);
						.line-height(2.5);
					}		
				}		
			}
		}
	}
}

#subnav, #subnav-ext {
		float: left;
}

#sidenav {
	width: 8%;
	margin-left: 92%;
}

#sidenav li {
	padding-top: 4px;	
	text-align: right;
		
	a:link, a:active, a:visited, a:hover {
		margin: 0;
		margin-left: 12px;		
	}
	
}


/*-------------------------------HOME-------------------------------------*/

#home #maincontent {
	padding-top: 0;
}

/*-------------------------------Team-------------------------------------*/

#team, #menschen {

	#team_galery {
	
		display: block;
		.font-size(0.75);
		color: white;
		.semi-bold-font;
		text-align: center;
		cursor: pointer;		
		
		.team_entry {
			height: 100px;
			float: left;
			z-index: 100;
			overlow: hidden;
			
			.team_img {
				width: 16.66%
			}
			
			.team_txt {
				position: relative;
				bottom: 32px;
				display: block;
				height: 28px;
				background: url('@{imagePath}/bg_team.png') 0; 	
				padding-top: 4px;							
			}
		}	
		
		
	}
}


/*-------------------------------GESCHAEFTSFUEHRUNG-------------------------------------*/

#geschaeftsfuehrung {
	
	#geschaeftsfuehrung_galery {
			
		.geschaeftsfuehrung_entry {
			display: block;
			float: left;
			.semi-bold-font;
			.font-size(0.75);
			cursor: pointer;
			margin-bottom: 40px;			
			
			.geschaeftsfuehrung_img {
				display: block;
				margin-bottom: 8px;
			}		
			
			&.on {
				color: @darkgrey;
				
				.geschaeftsfuehrung_img {
					opacity: 0.3;
				}
			}		
		}
	}
		
	.geschaeftsfuehrung_details {
			.regular-font;
			color: @darkgrey;
			.font-size(0.75);
			
			img {
				display: block;
				float: left;
				margin-right: 20px;
				margin-bottom: 40px;
			}
		
			h4 {
									
				.font-size(1.125);
				
				span {
					.regular-font;
					.font-size(0.75);
					color: @darkgrey;
				}
			}
			
			p {
				margin-bottom: 24px;
			}
	}
}

/*-------------------------------STANDORTE/KONTAKT/ANFAHRT-------------------------------------*/

#standorte,#kontakt,#anfahrt {

	#maincontent {
				
		.row {		
			display: block;			
			.light-font;
			.line-height(1.5);
			margin-bottom: 20px;
			
			h2 {
				.regular-font;
				.font-size(1.125);
				.line-height(1.125);
			}
			
			p {
				margin-bottom: 24px;
			}
					
			.col-left {
				width: 50%;
				float: left;
				
				&.txt-right {
					text-align: right;
				}
			}
			.col-right {
				margin-left: 52%;
			}
			
		}
		
	}
}


/*-------------------------------MITARBEIT-------------------------------------*/

#mitarbeit {

	.regular-font;
	.font-size(0.875);
	.line-height(1.5);	
		
	#mitarbeit-galery {
		margin: auto;
		margin-top: 25px;
	}
	
	.mitarbeit-galery-left {
		width: 49.25%;
		float: left;
		margin-right: 1.5%;	
	}
	
	.mitarbeit-galery-right {
		width: 49.25%;
		margin-left: 50.75%;		
	}

	.mitarbeit-entry {
	
		display: block;			
		
		margin-bottom: 16px;
		
		.mitarbeit-title {
			.font-size(1.125);
			margin-bottom: 5px;
			
			span {
				.font-size(0.875);
			}
		}
		
		.mitarbeit-intro {
			.light-font;
			.font-size(0.8125);		
				
			p {
				margin-bottom: 0;
			}
		}
		
		.mitarbeit-fulltxt {
			.light-font;
			.line-height(1.125);
		}
		
		.mitarbeit-readmore {
			cursor: pointer;
		}	
	}
	
	.category-desc {
		display: block;
		width: 48.4%;	
		.line-height(1.125);
		margin-left: 50.75%;
		margin-right: 1.5%;		
		margin-top: 40px;
		
		a, a:link, a:active, a:hover,
		a:visited {
			.regular-font;
			.font-size(0.875);
			.line-height(1.5);
		}
	}
	
}

/*-------------------------------Miteinander-------------------------------------*/

#miteinander {

	.regular-font;
	.font-size(0.875);
	.line-height(1.5);	
	
	
	#header-slogan {
		display: none;
		.font-size(1.88);
		color: @yellow-green;
		padding-left: 27px;
	}
		
	#miteinander-galery {
		margin: auto;
		margin-top: 25px;
	}
	
	.miteinander-galery-left {
		width: 49.25%;
		float: left;
		margin-right: 1.5%;	
	}
	
	.miteinander-galery-right {
		width: 49.25%;
		margin-left: 50.75%;		
	}

	.miteinander-entry {
	
		display: block;			
		
		margin-bottom: 16px;
		
		.miteinander-title {
			.font-size(1.125);
			.line-height(1.5);
			margin-bottom: 5px;			
		}
		
		.miteinander-intro {
			.light-font;
			.font-size(0.8125);	
			.line-height(1.125);	
			p {
				margin-bottom: 0;
			}
		}
		
		.miteinander-fulltxt {
			display: none;
			.light-font;
			.font-size(0.8125);	
			.line-height(1.125);
		}
		
		.miteinander-readmore {
			cursor: pointer;
		}	
	}
	
	.category-desc {
		display: block;
		width: 48.4%;	
		.line-height(1.125);
		margin-left: 50.75%;
		margin-right: 1.5%;		
		margin-top: 40px;
		
		a, a:link, a:active, a:hover,
		a:visited {
			.regular-font;
			.font-size(0.875);
			.line-height(1.5);
		}
	}
	
}


/*-------------------------------ARCHITEKTUR/PROJEKTENTWICKLUNG/WISSENSCHAFT/AKADEMIE/GEOMANTIE/GUTACHTEN/IMPRESSUM-------------------------------------*/

#erfahrung, #architektur, #projektentwicklung, #wissenschaft,
#akademie, #geomantie, #gutachten, #impressum {
	
	.light-font;
	.line-height(1.5);
	
	h1 {
		.font-size(2.25);
		.line-height(3);
		margin-bottom: 25px;
	}
	
	h2 {
		.semi-bold-font;
		.font-size(0.75);
		.line-height(0.75);
		margin-bottom: 12px;
	}

	.col-left {
		width: 48.94%;
	}
	
	.col-right {
		width: 49%;
		.font-size(1.125);
		margin-left: 51%;
	}
}

/*-------------------------------IMPRESSUM-------------------------------------*/

#impressum {
	
	.row {
	
		margin-top: 30px;
		margin-bottom: 40px;
		
		h2 {
			.font-size(1);
			margin-bottom: 30px;
		}

		.col-left,.col-right,
		a:link, a:active, a:visited, a:hover  {
			.regular-font;
			.font-size(0.875);
			.line-height(1.25)	;
		}
		
	}
}